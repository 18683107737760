<template>
  <div class="AspectsJuridique" >
    <validationCartouche
      :hasCedantValidated="aspectsJuridiquesActiviteCedee.has_cedant_validated"
      :hasRepreneurValidated="aspectsJuridiquesActiviteCedee.has_repreneur_validated"
    />
    <div class="container-fluid">
      <h2 class="gavc-h2">Aspects juridiques de l’activité cédée </h2>

      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$emit('previous-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente
        </button>
        <button
          v-if="aspectsJuridiquesActiviteCedee.has_repreneur_validated && aspectsJuridiquesActiviteCedee.has_cedant_validated"
          class="gavc-btn"
          @click="$emit('next-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Accéder à l'étape suivante
        </button>
      </div>


      <div v-if="userGroupContains(['REPRENEUR'])">
        <div class="gavc-field">
          <label class="gavc-label" for="aspectsJuridiquesActiviteCedee-repreneur_comment-input">
            <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="aspectsJuridiquesActiviteCedee-repreneur_comment-input"
            maxlength="1000"
            rows="5"
            @change="modifyAspectsJuridique"
            placeholder="Je laisse mon commentaire au cédant"
            type="text"
            v-model="aspectsJuridiquesActiviteCedee.repreneur_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="aspectsJuridiquesActiviteCedee.repreneur_comment">
        <strong> Le repreneur vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{aspectsJuridiquesActiviteCedee.repreneur_comment}} </span>
      </div>

      <auditExplanation
        v-if="userGroupContains(['CEDANT'])"
        :isCedant="true"
        :isRepreneur="false"
      />

      <p class="gavc-chapo mt-4" v-else>
        <strong class="gavc-text-tory">En tant que repreneur</strong>,
        <u>vous n'avez aucun document ni information à compléter</u>, il vous est juste nécessaire de valider les documents chargés par le cédant
        puis de valider cette phase.
      </p>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.identification-juridique-collapse>
        Identification juridique
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="identification-juridique-collapse" role="tabpanel">
        <h3 class="gavc-h3 gavc-text-green mt-4"> Status en vigueur </h3>
        <p>
          Documents à charger : <strong>Statuts en vigueur de l'entreprise cédée</strong>
          <span
            v-b-tooltip.hover title="Pour consulter le statut d'une entreprise, rendez-vous sur https://www.infogreffe.fr"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'aspectsJuridiquesActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'aspectsJuridiquesActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'STATUTS', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          @item-validated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', false)"
          :items="filterDocuments('STATUTS', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'STATUTS', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          type="STATUTS"
        />

        <h3 class="gavc-h3 gavc-text-green"> Kbis </h3>
        <p>
          Documents à charger : <strong>Extrait Kbis de moins de 3 mois de l'entreprise cédée</strong>
          <span
            v-b-tooltip.hover title="Pour consulter le Kbis d'une entreprise, rendez-vous sur https://www.infogreffe.fr"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'aspectsJuridiquesActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'aspectsJuridiquesActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'KBIS', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          @item-validated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', false)"
          :items="filterDocuments('KBIS', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'KBIS', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          type="KBIS"
        />

        <h3 class="gavc-h3 gavc-text-green"> Informations sur l'actionnariat </h3>
        <p v-if="userGroupContains(['CEDANT'])">
          En tant que cédant, veuillez renseigner les informations ci-dessous <strong> puis enregistrer vos informations </strong>
        </p>
        <p v-else>
          En tant que repreneur, veuillez prendre connaissance des éléments chargés et des informations renseignés, puis valider.
        </p>

        <p class="gavc-label mt-3">
          La société possède-t-elle plusieurs actionnaires ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires-input-1"
                     name="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires-input-1" v-model="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires"
                     :disabled="!cedantCanModified">
              <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires-input-2"
                     name="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires-input-2" v-model="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires"
                     :disabled="!cedantCanModified">
              <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires-input-2">
                Non
              </label>
            </div>
          </div>
        </div>

        <div v-if="aspectsJuridiquesActiviteCedee.is_multiple_actionnaires">

          <p class="gavc-label mt-3">
            La cession concerne-t-elle l’intégralité des parts de la société cédée ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees-input-1"
                       name="aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees-input-1" v-model="aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees"
                       :disabled="!cedantCanModified">
                <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees-input-2"
                       name="aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees-input-2" v-model="aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees"
                       :disabled="!cedantCanModified">
                <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>

          <div v-if="!aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees">

            <p class="gavc-label mt-3">
              Êtes-vous le seul associé à céder vos parts ?
              <span class="gavc-required"> * </span>
            </p>
            <div class="gavc-field">
              <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                <div class="gavc-radio-icon">
                  <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_only_associes_to_sell-input-1"
                         name="aspectsJuridiquesActiviteCedee.is_only_associes_to_sell-input-1" v-model="aspectsJuridiquesActiviteCedee.is_only_associes_to_sell"
                         :disabled="!cedantCanModified">
                  <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_only_associes_to_sell-input-1">
                    Oui
                  </label>
                </div>
                <div class="gavc-radio-icon">
                  <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_only_associes_to_sell-input-2"
                         name="aspectsJuridiquesActiviteCedee.is_only_associes_to_sell-input-2" v-model="aspectsJuridiquesActiviteCedee.is_only_associes_to_sell"
                         :disabled="!cedantCanModified">
                  <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_only_associes_to_sell-input-2">
                    Non
                  </label>
                </div>
              </div>
            </div>

            <div v-if="!aspectsJuridiquesActiviteCedee.is_only_associes_to_sell">

              <p class="gavc-label mt-3">
                Les parts cédées représentent plus de la moitié des parts de la société ?
                <span class="gavc-required"> * </span>
              </p>
              <div class="gavc-field">
                <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                  <div class="gavc-radio-icon">
                    <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_sold_parts_majorite-input-1"
                           name="aspectsJuridiquesActiviteCedee.is_sold_parts_majorite-input-1" v-model="aspectsJuridiquesActiviteCedee.is_sold_parts_majorite"
                           :disabled="!cedantCanModified">
                    <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_sold_parts_majorite-input-1">
                      Oui
                    </label>
                  </div>
                  <div class="gavc-radio-icon">
                    <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_sold_parts_majorite-input-2"
                           name="aspectsJuridiquesActiviteCedee.is_sold_parts_majorite-input-2" v-model="aspectsJuridiquesActiviteCedee.is_sold_parts_majorite"
                           :disabled="!cedantCanModified">
                    <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_sold_parts_majorite-input-2">
                      Non
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <p class="gavc-label mt-3">
            Les statuts prévoient-ils une clause d’agrément ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_status_agrement_clause-input-1"
                       name="aspectsJuridiquesActiviteCedee.is_status_agrement_clause-input-1" v-model="aspectsJuridiquesActiviteCedee.is_status_agrement_clause"
                       :disabled="!cedantCanModified">
                <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_status_agrement_clause-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_status_agrement_clause-input-2"
                       name="aspectsJuridiquesActiviteCedee.is_status_agrement_clause-input-2" v-model="aspectsJuridiquesActiviteCedee.is_status_agrement_clause"
                       :disabled="!cedantCanModified">
                <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_status_agrement_clause-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>

          <p class="gavc-label mt-3">
            Les autres actionnaires approuvent-ils la cession ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_cession_accepted-input-1"
                       name="aspectsJuridiquesActiviteCedee.is_cession_accepted-input-1" v-model="aspectsJuridiquesActiviteCedee.is_cession_accepted"
                       :disabled="!cedantCanModified">
                <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_cession_accepted-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_cession_accepted-input-2"
                       name="aspectsJuridiquesActiviteCedee.is_cession_accepted-input-2" v-model="aspectsJuridiquesActiviteCedee.is_cession_accepted"
                       :disabled="!cedantCanModified">
                <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_cession_accepted-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>

          <div>
            <strong>
              Veuillez renseigner les informations concernant les autres actionnaire de votre entreprise (prénom, nom et email)
            </strong>
            <div
              class="w-50 mt-2 p-2 gavc-bg-bizarre"
              style="border-radius: 10px;"
              v-for="actionnaire in aspectsJuridiquesActiviteCedee.actionnaires" :key="actionnaire.id"
            >
              Prénom : {{actionnaire.first_name}} / Nom : {{actionnaire.last_name}} / Email : {{actionnaire.email}}
              <button
                v-if="cedantCanModified"
                class="ml-4 float-right"
                style="background-color: transparent !important; border: 0 !important;"
                @click="deleteAuditSectionInformation(actionnaire.id, 'actionnaire')">
                <i class="gavc-icon gavc-icon-cross"></i>
              </button>
            </div>
            <form v-if="cedantCanModified" class="form-inline mt-4" v-on:submit.prevent="addActionnaire()">
              <input
                class="gavc-input"
                type="text"
                id="add-actionnaire-first-name"
                placeholder="Prénom"
                name="add-actionnaire-first-name"
                v-model="newActionnaireFirstName"
              >
              <input
                class="gavc-input"
                type="text"
                id="add-actionnaire-last-name"
                placeholder="Nom"
                name="add-actionnaire-last-name"
                v-model="newActionnaireLastName"
              >
              <input
                class="gavc-input"
                type="email"
                id="add-actionnaire-email"
                placeholder="Email"
                name="add-actionnaire-email"
                v-model="newActionnaireEmail"
              >
              <button
                class="gavc-btn"
                :disabled="$v.newActionnaireEmail.$invalid || $v.newActionnaireFirstName.$invalid || $v.newActionnaireLastName.$invalid"
                type="submit"
              >
                Ajouter un actionnaire
              </button>
            </form>
          </div>

          <p class="gavc-label mt-3">
            Avez-vous conclu un Pacte d’associés ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_pacte_associes-input-1"
                       name="aspectsJuridiquesActiviteCedee.is_pacte_associes-input-1" v-model="aspectsJuridiquesActiviteCedee.is_pacte_associes"
                       :disabled="!cedantCanModified">
                <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_pacte_associes-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_pacte_associes-input-2"
                       name="aspectsJuridiquesActiviteCedee.is_pacte_associes-input-2" v-model="aspectsJuridiquesActiviteCedee.is_pacte_associes"
                       :disabled="!cedantCanModified">
                <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_pacte_associes-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>

          <div v-if="aspectsJuridiquesActiviteCedee.is_pacte_associes">
            <p>
              Veuillez charger le pacte d'associés
            </p>
            <auditItem
              :disabled="!cedantCanModified"
              @document-deleted="deleteAuditItem($event, 'aspectsJuridiquesActiviteCedee')"
              @document-download="downloadAuditDocument($event, 'aspectsJuridiquesActiviteCedee')"
              @document-uploaded="createAuditDocument($event, 'PACTE_ACTIONNAIRES', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
              @item-validated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', true)"
              @item-unvalidated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', false)"
              :items="filterDocuments('PACTE_ACTIONNAIRES', 'CEDANT')"
              @justificatif-added="createAuditItemWithoutDocument($event, 'PACTE_ACTIONNAIRES', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
              type="PACTE_ACTIONNAIRES"
            />


            <p class="gavc-label mt-3">
              Le pacte prévoit-il une clause d’agrément ?
              <span class="gavc-required"> * </span>
            </p>
            <div class="gavc-field">
              <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                <div class="gavc-radio-icon">
                  <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte-input-1"
                         name="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte-input-1" v-model="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte"
                         :disabled="!cedantCanModified">
                  <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte-input-1">
                    Oui
                  </label>
                </div>
                <div class="gavc-radio-icon">
                  <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte-input-2"
                         name="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte-input-2" v-model="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte"
                         :disabled="!cedantCanModified">
                  <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte-input-2">
                    Non
                  </label>
                </div>
              </div>
            </div>

            <div v-if="aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte">
              <p class="gavc-label mt-3">
                Votre cession respecte-t-elle les stipulations du pacte d’associés ?
                <span class="gavc-required"> * </span>
              </p>
              <div class="gavc-field">
                <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                  <div class="gavc-radio-icon">
                    <input :value="true" type="radio" id="aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement-input-1"
                           name="aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement-input-1" v-model="aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement"
                           :disabled="!cedantCanModified">
                    <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement-input-1">
                      Oui
                    </label>
                  </div>
                  <div class="gavc-radio-icon">
                    <input :value="false" type="radio" id="aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement-input-2"
                           name="aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement-input-2" v-model="aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement"
                           :disabled="!cedantCanModified">
                    <label class="gavc-label" for="aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement-input-2">
                      Non
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-collapse>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.gouvernance-collapse>
        Gouvernance
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="gouvernance-collapse" role="tabpanel">
        <h3 class="gavc-h3 gavc-text-green mt-4"> Procès-verbaux </h3>

        <p>
          Documents à charger : <strong>Procès-verbaux de clôture des comptes et d'affectation du résultat des années N-3, N-2 et N-1</strong>
          <span
            v-b-tooltip.hover title="Pour consulter les procès verbaux, rendez-vous sur https://www.bodacc.fr"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>


        <h4 class="gavc-h4">PV de clôture des comptes et d’affectation du résultat N-3</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'aspectsJuridiquesActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'aspectsJuridiquesActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'PV_CLOTURE_N3', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          @item-validated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', false)"
          :items="filterDocuments('PV_CLOTURE_N3', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'PV_CLOTURE_N3', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          type="PV_CLOTURE_N3"
        />

        <h4 class="gavc-h4">PV de clôture des comptes et d’affectation du résultat N-2</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'aspectsJuridiquesActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'aspectsJuridiquesActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'PV_CLOTURE_N2', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          @item-validated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', false)"
          :items="filterDocuments('PV_CLOTURE_N2', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'PV_CLOTURE_N2', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          type="PV_CLOTURE_N2"
        />

        <h4 class="gavc-h4">PV de clôture des comptes et d’affectation du résultat N-1</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'aspectsJuridiquesActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'aspectsJuridiquesActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'PV_CLOTURE_N1', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          @item-validated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', false)"
          :items="filterDocuments('PV_CLOTURE_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'PV_CLOTURE_N1', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          type="PV_CLOTURE_N1"
        />

        <h3 class="gavc-h3 gavc-text-green">Autres : prise de participation, décisions structurantes</h3>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'aspectsJuridiquesActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'aspectsJuridiquesActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'ASPECTS_JURIDIQUES_OTHER', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          @item-validated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'aspectsJuridiquesActiviteCedee', false)"
          :items="filterDocuments('ASPECTS_JURIDIQUES_OTHER', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'ASPECTS_JURIDIQUES_OTHER', aspectsJuridiquesActiviteCedee.id, 'aspectsJuridiquesActiviteCedee')"
          type="ASPECTS_JURIDIQUES_OTHER"
        />
      </b-collapse>


      <!-- ######## CEDANT BUTTONS ########## -->
      <div v-if="!aspectsJuridiquesActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <validationSectionButton
            @modify="modifyAspectsJuridique"
            @validate="validateAspectsJuridique(true)"
          />
        </div>
      </div>
      <div v-else-if="aspectsJuridiquesActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <button
            class="gavc-btn gavc-btn--bone"
            @click="validateAspectsJuridique(false)"
          >
              Invalider cette étape
          </button>
        </div>
      </div>
    </div>


    <div v-if="!aspectsJuridiquesActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
      <div class="col-12 text-right">
        <validationSectionButton
          @validate="validateAspectsJuridique(true)"
          :withModifyButton="false"
        />
      </div>
    </div>
    <div v-else-if="aspectsJuridiquesActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
      <div class="col-12 text-right">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="validateAspectsJuridique(false)"
        >
            Invalider cette étape
        </button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="aspectsJuridiquesActiviteCedee.has_cedant_validated"
          text="Le cédant a validé toute la section"
        />
      </div>
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="aspectsJuridiquesActiviteCedee.has_repreneur_validated"
          text="Le repreneur a validé toute la section"
        />
      </div>
    </div>

    <div class="mt-4 text-center" v-if="aspectsJuridiquesActiviteCedee.has_repreneur_validated && aspectsJuridiquesActiviteCedee.has_cedant_validated">
      <button
        class="gavc-btn"
        @click="$emit('next-step')">
            Étape validée - Accéder à l'étape suivante
      </button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, minLength, maxLength, required } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import auditItemsMixins from '@/mixins/auditItemsMixins'
import auditSectionInformationMixins from '@/mixins/auditSectionInformationMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'

const apiConnectedService = new APIConnected()
const auditItem = () => import('@/components/audit/auditItem')
const auditExplanation = () => import('@/components/audit/auditExplanation')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const validationCartouche = () => import('@/components/layout/validationCartouche')
const validationSectionButton = () => import('@/components/audit/validationSectionButton')

export default {
  name: 'AspectsJuridique',
  props: {
    id: {
      type: Number,
    }
  },
  components: {
    auditExplanation,
    auditItem,
    hasValidatedMessage,
    validationCartouche,
    validationSectionButton
  },
  mixins: [
    auditItemsMixins,
    auditSectionInformationMixins,
    userMixins,
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isGettingAspectsJuridique: false,
      aspectsJuridiquesActiviteCedee: {
        audit: {
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              entreprise_cedee: {
                cedant: {
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        cedant_comment: "",
        repreneur_comment: "",
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],

        is_multiple_actionnaires: false,
        is_integralite_parts_concernees: false,
        is_only_associes_to_sell: false,
        is_sold_parts_majorite: false,
        is_status_agrement_clause: false,
        is_cession_accepted: false,
        actionnaires: [],
        is_pacte_associes: false,
        is_clause_agrement_in_pacte: false,
        is_cession_in_accord_with_clause_agrement: false,
      },

      newActionnaireEmail: '',
      newActionnaireFirstName: '',
      newActionnaireLastName: '',

    }
  },
  computed: {
    cedantCanModified () {
      return this.userGroupContains(['CEDANT']) && !this.aspectsJuridiquesActiviteCedee.has_cedant_validated
    },

    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.scrollToTop()
    this.getAspectsJuridique()
  },

  validations: {
    aspectsJuridiquesActiviteCedee: {
      is_multiple_actionnaires: {
        required,
      },
    },
    newActionnaireEmail: {
      required,
      email,
      minLength: minLength(2),
      maxLength: maxLength(240)
    },
    newActionnaireFirstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(240)
    },
    newActionnaireLastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(240)
    }
  },
  watch: {
    'aspectsJuridiquesActiviteCedee.is_multiple_actionnaires' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
    'aspectsJuridiquesActiviteCedee.is_integralite_parts_concernees' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
    'aspectsJuridiquesActiviteCedee.is_only_associes_to_sell' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
    'aspectsJuridiquesActiviteCedee.is_sold_parts_majorite' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
    'aspectsJuridiquesActiviteCedee.is_status_agrement_clause' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
    'aspectsJuridiquesActiviteCedee.is_cession_accepted' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
    'aspectsJuridiquesActiviteCedee.is_pacte_associes' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
    'aspectsJuridiquesActiviteCedee.is_clause_agrement_in_pacte' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
    'aspectsJuridiquesActiviteCedee.is_cession_in_accord_with_clause_agrement' () {
      if (!this.isGettingAspectsJuridique) {
        this.modifyAspectsJuridique()
      }
    },
  },

  methods: {
    filterDocuments (type, uploader) {
      return this.aspectsJuridiquesActiviteCedee.items.filter(item => item.type === type && item.uploader === uploader)
    },
    addActionnaire () {
      const data = {
        'section': this.aspectsJuridiquesActiviteCedee.id,
        'email': this.newActionnaireEmail,
        'first_name': this.newActionnaireFirstName,
        'last_name': this.newActionnaireLastName
      }
      this.createAuditSectionInformation(data, 'actionnaire')
    },
    informationAdded(data, sectionInformation) {
      if(sectionInformation === 'actionnaire') {
        this.aspectsJuridiquesActiviteCedee.actionnaires.push(data)
        this.newActionnaireEmail = ''
        this.newActionnaireFirstName = ''
        this.newActionnaireLastName = ''
      }
    },
    informationDeleted(informationId, sectionInformation) {
      if(sectionInformation === 'actionnaire') {
        this.aspectsJuridiquesActiviteCedee.actionnaires = this.aspectsJuridiquesActiviteCedee.actionnaires.filter(item => item.id !== informationId);
      }
    },
    documentModified () {
      this.getAspectsJuridique()
    },
    getAspectsJuridique () {
      this.isGettingAspectsJuridique = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'aspectsJuridiquesActiviteCedee', this.id)
      .then((result) => {
        this.aspectsJuridiquesActiviteCedee = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('AspectsJuridique getAspectsJuridique API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingAspectsJuridique = false
      })
    },

    modifyAspectsJuridique () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'aspectsJuridiquesActiviteCedee', this.id, this.aspectsJuridiquesActiviteCedee)
      .then((result) => {
        this.aspectsJuridiquesActiviteCedee = result.data
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          title: 'Informations mises à jour',
          id: 'notification-toast',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('AspectsJuridique modifyAspectsJuridique API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    validateAspectsJuridique (validationStatus) {
      const data = {}
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, "aspectsJuridiquesActiviteCedee",  this.id, data)
      .then((result) => {
        this.aspectsJuridiquesActiviteCedee = result.data
        if (validationStatus) {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            id: 'notification-toast',
            title: 'Section validée',
            variant: 'success',
            solid: true
          })
        }
        else {
          this.$bvToast.hide('notification-toast')
            this.$bvToast.toast("Vous avez bien invalidé cette partie, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('AspectsJuridique validateAspectsJuridique API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

  }
}
</script>
